/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./EditAddressDialog.vue?vue&type=template&id=45abe5a8"
import script from "./EditAddressDialog.vue?vue&type=script&lang=js"
export * from "./EditAddressDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/code/src/components/input/Input.vue').default,ZipcodeInput: require('/code/src/components/input/ZipcodeInput.vue').default,AddressAlert: require('/code/src/components/widgets/AddressAlert.vue').default,AlertArea: require('/code/src/components/AlertArea.vue').default,CustomDialog: require('/code/src/components/dialog/CustomDialog.vue').default})
