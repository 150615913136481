import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomDialog', {
    attrs: {
      "form": "",
      "loading": _vm.loading,
      "title": _vm.address ? _vm.$t('action.editAddress') : _vm.$t('action.addAddress'),
      "negative": _vm.$t('action.cancel'),
      "positive": _vm.address ? _vm.$t('action.save') : _vm.$t('action.add'),
      "disabled-positive": !_vm.isSubmitEnable,
      "before-cancel": _vm.beforeCancel
    },
    on: {
      "click:positive": _vm.onClickSave,
      "close": _vm.resetForm
    },
    model: {
      value: _vm.display,
      callback: function callback($$v) {
        _vm.display = $$v;
      },
      expression: "display"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.label,
      "label": _vm.$t('registeredName'),
      "placeholder": _vm.$t('placeholder.addressLabel'),
      "required": ""
    },
    model: {
      value: _vm.form.label,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "label", $$v);
      },
      expression: "form.label"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('ZipcodeInput', {
    attrs: {
      "initial-value": _vm.initialFormData.postcode,
      "label": _vm.$t('postcode'),
      "placeholder": _vm.$t('placeholder.postcode')
    },
    on: {
      "update": function update($event) {
        _vm.form.pref = $event.pref;
        _vm.form.city = $event.city;
      }
    },
    model: {
      value: _vm.form.postcode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "postcode", $$v);
      },
      expression: "form.postcode"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.pref,
      "type": "select",
      "items": _vm.$prefsForSelect,
      "label": _vm.$t('pref'),
      "placeholder": _vm.$t('placeholder.pref')
    },
    model: {
      value: _vm.form.pref,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pref", $$v);
      },
      expression: "form.pref"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.city,
      "label": _vm.$t('city'),
      "placeholder": _vm.$t('placeholder.city')
    },
    model: {
      value: _vm.form.city,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "city", $$v);
      },
      expression: "form.city"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.street1,
      "label": _vm.$t('street1'),
      "placeholder": _vm.$t('placeholder.street1')
    },
    model: {
      value: _vm.form.street1,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "street1", $$v);
      },
      expression: "form.street1"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "initial-value": _vm.initialFormData.street2,
      "label": _vm.$t('street2'),
      "placeholder": _vm.$t('placeholder.street2')
    },
    model: {
      value: _vm.form.street2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "street2", $$v);
      },
      expression: "form.street2"
    }
  })], 1), _vm._v(" "), _c(VFadeTransition, [!_vm.$isFillAddress(_vm.form) && !_vm.$isEmptyAddress(_vm.form) ? _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('AlertArea', {
    attrs: {
      "icon": "mdi-lightbulb-on-outline",
      "no-margin": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('AddressAlert', {
          attrs: {
            "address": _vm.form
          }
        })];
      },
      proxy: true
    }], null, false, 2335900887)
  })], 1) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };