import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VCard, _vm._g({
    staticClass: "pa-4 d-flex flex-column",
    class: {
      selectable: _vm.selectable,
      selected: _setup.checked
    },
    attrs: {
      "flat": "",
      "outlined": "",
      "min-height": _vm.minHeight,
      "height": _vm.height,
      "disabled": _vm.disabled
    }
  }, _vm.selectable ? {
    click: _setup.onClick
  } : {}), [_c('span', {
    staticClass: "text-subtitle-1 d-flex align-middle"
  }, [_vm.selectable ? _c(_setup.VCheckbox, {
    ref: "checkboxRef",
    staticStyle: {
      "pointer-events": "none"
    },
    attrs: {
      "id": _vm.address.id,
      "value": _setup.checked,
      "hide-details": "",
      "dense": "",
      "off-icon": "$radioOff",
      "on-icon": "$radioOn",
      "ripple": false
    },
    on: {
      "change": _setup.onChange
    }
  }) : _vm._e(), _vm._v(" "), _c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "size": "20"
    }
  }, [_vm._v(_vm._s(_setup.icon))]), _vm._v(" "), _c('span', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis",
      value: 1,
      expression: "1"
    }]
  }, [_vm._v(_vm._s(_vm.address.label))])], 1), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 mt-2 d-flex align-middle"
  }, [_vm._v("\n    " + _vm._s(_vm.$address(_vm.address)) + "\n    "), !_vm.$isFillAddress(_vm.address) ? _c('FloatingTooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VIcon, _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "small": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v("\n          mdi-alert-outline\n        ")])];
      }
    }], null, false, 3829812758)
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('warning.notInput', [_vm.$noInputAddressText(_vm.address)])) + "\n      ")])]) : _vm._e()], 1), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.editable ? _c('div', {
    staticClass: "d-flex mt-2 justify-end overflow-hidden"
  }, [_c('TextButton', {
    attrs: {
      "value": _vm.$t('action.edit'),
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _setup.emits('click:edit', _setup.cloneDeep(_vm.address));
      }
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "mx-2 my-1",
    attrs: {
      "vertical": ""
    }
  }), _vm._v(" "), _c('TextButton', {
    attrs: {
      "value": _vm.$t('action.delete'),
      "color": "error",
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _setup.emits('click:delete', _setup.cloneDeep(_vm.address));
      }
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };