import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomDialog', {
    attrs: {
      "form": "",
      "loading": _vm.loading,
      "title": _vm.accountId ? _vm.$t('action.editAccount') : _vm.$t('action.addAccount'),
      "negative": _vm.$t('action.cancel'),
      "positive": _vm.accountId ? _vm.$t('action.save') : _vm.$t('action.add'),
      "disabled-positive": !_vm.isSubmitEnable,
      "before-cancel": _vm.beforeCancel
    },
    on: {
      "click:positive": _vm.onClickSave,
      "open": _vm.resetForm
    },
    model: {
      value: _vm.display,
      callback: function callback($$v) {
        _vm.display = $$v;
      },
      expression: "display"
    }
  }, [_vm.isEditDisabled ? _c('AlertArea', {
    staticClass: "mb-2",
    attrs: {
      "type": "info",
      "no-margin": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        var _vm$account, _vm$account2, _vm$account3, _vm$account4;
        return [_vm._v("\n      " + _vm._s(_vm.$t('warning.disabledEdit', [_vm.$t('account')])) + "\n      "), (_vm$account = _vm.account) !== null && _vm$account !== void 0 && _vm$account.crm_id ? _c('div', {
          staticClass: "d-flex align-center"
        }, [(_vm$account2 = _vm.account) !== null && _vm$account2 !== void 0 && _vm$account2.get_crm_display ? _c('LinkText', {
          attrs: {
            "text": (_vm$account3 = _vm.account) === null || _vm$account3 === void 0 ? void 0 : _vm$account3.get_crm_display,
            "to": (_vm$account4 = _vm.account) === null || _vm$account4 === void 0 ? void 0 : _vm$account4.crm_url
          }
        }) : _vm._e(), _vm._v(" "), _c('span', {
          staticClass: "text-caption secondary-text--text ml-2"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('lastSyncAt', [_vm.account.last_synced_at ? _vm.$datetime(_vm.account.last_synced_at) : _vm.$t('notSync')])) + "\n        ")])], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 4178596134)
  }) : _vm._e(), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _vm.initialFormData.name,
      "label": _vm.$t('accountName'),
      "required": "",
      "disabled": _vm.isEditDisabled
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _vm.initialFormData.site_url,
      "label": _vm.$t('webSite'),
      "placeholder": _vm.$t('placeholder.urlHint'),
      "disabled": _vm.isEditDisabled
    },
    model: {
      value: _vm.form.site_url,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "site_url", $$v);
      },
      expression: "form.site_url"
    }
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _vm.form.shipping_addresses,
      "initial-value": _vm.initialFormData.shipping_addresses
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.form, "shipping_addresses", $event);
      }
    }
  }, [_c('SmallLabel', {
    staticClass: "mt-4 pb-1",
    attrs: {
      "label": _vm.$t('address') + (_vm.form.shipping_addresses.length > 0 ? "(".concat(_vm.form.shipping_addresses.length, "/").concat(_vm.$t('case', [10]), ")") : '')
    }
  }), _vm._v(" "), _c('AddressList', {
    attrs: {
      "editable": "",
      "addresses": _vm.form.shipping_addresses,
      "synced": _vm.isEditDisabled
    },
    on: {
      "click:add": function clickAdd($event) {
        return _vm.$refs.addressDialog.showAccount(_vm.accountId);
      },
      "click:edit": function clickEdit($event) {
        return _vm.$refs.addressDialog.showAccount(_vm.accountId, $event);
      },
      "click:delete": _vm.onClickDeleteAddress
    }
  })], 1), _vm._v(" "), _c('EditAddressDialog', {
    ref: "addressDialog",
    attrs: {
      "commit": false
    },
    on: {
      "change": _vm.onChangeAddress,
      "create": _vm.onCreateAddress
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };