import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c(VFadeTransition, [_setup.addresses.length >= 10 ? _c('AlertArea', {
    staticClass: "mb-2",
    attrs: {
      "type": "info",
      "no-margin": "",
      "message": _vm.$t('warning.limitOverAddresses')
    }
  }) : _vm._e()], 1), _vm._v(" "), _setup.addresses.length ? _c(VRow, [_vm._l(_setup.addresses, function (address) {
    return _c(VCol, {
      key: address.id,
      attrs: {
        "cols": _setup.cols
      }
    }, [_c('AddressCard', {
      attrs: {
        "value": _setup.dataSelectedId === address.id,
        "selectable": _setup.isSelectMode,
        "editable": _vm.editable,
        "address": address,
        "mandatory": _vm.mandatory,
        "disabled": _vm.synced && !!address.crm,
        "height": "100%"
      },
      on: {
        "update:value": function updateValue($event) {
          return _setup.onChangeSelectAddress(address, $event);
        },
        "click:edit": function clickEdit($event) {
          return _setup.emits('click:edit', $event);
        },
        "click:delete": function clickDelete($event) {
          return _setup.emits('click:delete', $event);
        }
      }
    })], 1);
  }), _vm._v(" "), _vm.editable && _setup.addresses.length < 10 ? _c(VCol, {
    attrs: {
      "cols": _setup.cols
    }
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-outline-dotted tw-w-full tw-h-full tw-min-h-[105px]",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click:add', $event);
      }
    }
  }, [_c('mdi-plus-circle', {
    staticClass: "tw-mr-2 tw-size-6 tw-shrink-0"
  }), _vm._v("\n        " + _vm._s(_vm.$t('action.doAddAddress')) + "\n      ")], 1)]) : _vm._e()], 2) : _vm.editable ? _c('button', {
    staticClass: "tw-btn tw-btn-outline-dotted tw-w-full",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click:add', $event);
      }
    }
  }, [_c('mdi-plus-circle', {
    staticClass: "tw-mr-2 tw-size-6"
  }), _vm._v("\n    " + _vm._s(_vm.$t('action.doAddAddress')) + "\n  ")], 1) : _c('div', {
    staticClass: "text-body-2 disabled-text--text"
  }, [_setup.isSelectMode ? [_vm._v("\n      " + _vm._s(_vm.$t('warning.noSelectableAddresses')) + "\n    ")] : [_vm._v("\n      " + _vm._s(_vm.$t('warning.noAddresses')) + "\n    ")]], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };