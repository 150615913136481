/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AddressCard.vue?vue&type=template&id=7bdedbdf"
import script from "./AddressCard.vue?vue&type=script&lang=ts&setup=true"
export * from "./AddressCard.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default})
